import axios from 'axios';
import config from "../lib/config.js";
// import 
import { Decryptdata, Encryptdata } from '../lib/encode_decode.js';
import { AppenData } from './appendata.js';

export const loginAdmin = async(payload)=>{
    console.log("adminlogin ",payload,config.AdminAPI)
    let data = {"data": Encryptdata(payload)};
    console.log("respond send data", data)
    try{
        const resp = await axios({
            'method':'POST',
            'url':`${config.AdminAPI}/user/adminlogin`,
            "data": data
           
            
        })
        console.log("member resp",resp.data)
        resp.data = Decryptdata(resp?.data)
        console.log("returned response",resp.data)
        if(resp.data.token)
            localStorage.setItem("token",resp.data.token);

        return resp.data;
    }
    catch(err){
        console.log("err",err)
    }


}
export const getpolynyx=async()=>{
    try{
        var resp = await axios({
                'method':'GET',
                'url':`${config.AdminAPI}/user/getpolynyx`,
                "headers":{
                    "Content-Type": 'application/json',
        
                    "Authorization":localStorage.getItem("token")
                },

        })
        console.log("member resp",resp.data)
      let response = Decryptdata(resp?.data)
        
        return response;
    }
    catch(err){console.log("err in article list",err)}
}
//newlist
export const getnewslist=async()=>{
    try{
        var resp = await axios({
                'method':'GET',
                'url':`${config.AdminAPI}/user/getnewslist`,
                "headers":{
                    "Content-Type": 'application/json',
        
                    "Authorization":localStorage.getItem("token")
                },

        })
        console.log("member resp",resp.data)
      let response = Decryptdata(resp?.data)

        return response;
    }
    catch(err){console.log("err in article list",err)}
}
//social
export const addSocial = async (data) => {
    console.log("sklfs",data)
  try { 
    var formdata = AppenData(data)
      let respData = await axios({
          'method': 'post',
          'url': `${config.AdminAPI}/user/addsociallinks`,
          "headers":{
            'Content-Type': 'multipart/form-data',

            "Authorization":localStorage.getItem("token")
        },
        "data":formdata[0]

      });

      let response = Decryptdata(respData?.data)
      return response;
  }catch (error) {
      console.log("errr")
  }
}

export const getSocialData  = async()=>{
    
    try{
        var resp = await axios({
          
                'method':'GET',
                'url':`${config.AdminAPI}/user/getsociallinks`,
                "headers":{
                    "Content-Type": 'application/json',
        
                    "Authorization":localStorage.getItem("token")
                },
        })
        console.log("tokonwer resp",resp.data)
      let response = Decryptdata(resp?.data)

        return response;
    }
    catch(err){console.log("err in gettok owner",err)}
  }

  export const changesocialstate = async(data)=>{
    console.log("token data",data)
    try{
        var resp = await axios({
            "method":"POST",
            "url":`${config.AdminAPI}/user/changesocialstate`,
            "headers":{
                "Content-Type": 'application/json',
                "Authorization":localStorage.getItem("token")
            },
            "data":{data:Encryptdata(data)},
        })
  
        let response = Decryptdata(resp?.data)
        return response;
    }
    catch(err){
        console.log('add token err',err)
    }
  }

// export const editDeleteSocial = async(data)=>{
//     console.log("token data",data)
//     var formdata = AppenData(data)
//     try{
//         var resp = await axios({
//             "method":"POST",
//             "url":`${config.AdminAPI}/user/editdeletesocial`,
//             "headers":{
//                 "Content-Type": 'multipart/form-data',

//                 "Authorization":localStorage.getItem("token")
//             },
//             "data":formdata[0],
//         })
  
//         // let response = Decryptdata(resp?.data)
//         return resp?.data;
//     }
//     catch(err){
//         console.log('add token err',err)
//     }
//   }
export const addbulkusers  = async(data)=>{
    console.log('====================================');
    console.log("dafatata",data);
    console.log('====================================');
    try{
        var resp = await axios({
          
                'method':'post',
                'url':`${config.AdminAPI}/user/exceltomail`,
                'headers' : {
                    "content-type":"multipart/form-data",
                     "Authorization":localStorage.getItem("token")
                },
                'data' : data
        })
        console.log("bulk users resp",resp.data)
        let response = Decryptdata(resp?.data)
        return response;
    }
    catch(err){console.log("err in bulk users",err)}
  }


export const sendSubscriberMail = async (data) => {
    console.log("sklfs",data)
  try { 
      let respData = await axios({
          'method': 'post',
          'url': `${config.AdminAPI}/user/sendSubscribeMail`,
          "headers":{
            "Content-Type": 'application/json',

            "Authorization":localStorage.getItem("token")
        },
        "data":{data:Encryptdata(data)},
      });

      let response = Decryptdata(respData?.data)
      return response;
  }catch (error) {
      return {
          loading: false,
          error : error
      }
  }
}

export const getSubscribers  = async()=>{
    
    try{
        var resp = await axios({
          
                'method':'GET',
                'url':`${config.AdminAPI}/user/getSubscribers`,
                "headers":{
                    "Content-Type": 'application/json',
        
                    "Authorization":localStorage.getItem("token")
                },
        })
        console.log("tokonwer resp",resp.data)
        let response = Decryptdata(resp?.data)
        
        return response;
    }
    catch(err){console.log("err in gettok owner",err)}
  }


  export const changeMaySent = async(data)=>{
    console.log("token data",data)
    try{
        var resp = await axios({
            "method":"POST",
            "url":`${config.AdminAPI}/user/changemaysent`,
            "headers":{
              "Content-Type": 'application/json',
  
                "Authorization":localStorage.getItem("token")
            },
            "data":{data:Encryptdata(data)},
        })
  
        let response = Decryptdata(resp?.data)
          return response;
    }
    catch(err){
        console.log('add token err',err)
    }
  }