import { lazy } from "react";



const Routes = [
    {
        path: "/",
        component: lazy(() => import('../loginpages/Login.js')),
        name: '/'
    },
    {
        path: "/login/admin",
        component: lazy(() => import('../loginpages/Login.js')),
    
    },

  
    {
        path: "/users",
        component: lazy(() => import('../user/userlist')),
        name: '/admin'
    },
    {
        path: "/userdetail",
        component: lazy(() => import('../user/userdetail')),
        name: '/admin'
    },
    {
        path: "/faq",
        component: lazy(() => import('../faqlist/faqlist')),
        name: '/admin'
    },
    {
        path: "/editfaq",
        component: lazy(() => import('../faqlist/editfaq')),
        name: '/admin'
    },  {
        path: "/addfaq",
        component: lazy(() => import('../faqlist/addfaq')),
        name: '/admin'
    },
    {
        path: "/cmslist",
        component: lazy(() => import('../CMS/cmsbox')),
        name: '/admin'
    },
    {
        path: "/editcms/:data",
        component: lazy(() => import('../CMS/editcms')),
        name: '/admin'
    },
 //whypolynyx
 {
 path: "/addpolynyx",
 component: lazy(() => import('../whyPolynyx/addpolynyx.js')),
 name: '/admin'
},
{
    path: "/editpolynyx",
    component: lazy(() => import('../whyPolynyx/editpolynyx.js')),
    name: '/admin'
   },
   {
    path: "/listpolynyx",
    component: lazy(() => import('../whyPolynyx/listpolynyx.js')),
    name: '/admin'
   },

   //Roadmap
   {
    path: "/addroadmap",
    component: lazy(() => import('../roadmap/addroadmap.js')),
    name: '/admin'
   },
   {
       path: "/editroadmap",
       component: lazy(() => import('../roadmap/editroadmap.js')),
       name: '/admin'
      },
      {
       path: "/roadmaplist",
       component: lazy(() => import('../roadmap/roadmaplist.js')),
       name: '/admin'
      },
//News and events
{
    path: "/addnews",
    component: lazy(() => import('../New and events/addnews.js')),
    name: '/admin'
   },
   {
       path: "/editnews",
       component: lazy(() => import('../New and events/editnews.js')),
       name: '/admin'
      },
      {
       path: "/newslist",
       component: lazy(() => import('../New and events/newslist.js')),
       name: '/admin'
      },

//sociallink
{
    path: "/addsocial",
    component: lazy(() => import('../socialLinks/addsocial.js')),
    name: '/admin'
   },
   {
       path: "/editsocial",
       component: lazy(() => import('../socialLinks/editsocial.js')),
       name: '/admin'
      },
      {
       path: "/sociallist",
       component: lazy(() => import('../socialLinks/sociallist.js')),
       name: '/admin'
      },
      //subscriber
      {
        path: "/addusers",
        component: lazy(() => import('../subscriber/addusers.js')),
        name: '/admin'
       },
       {
           path: "/sendmail",
           component: lazy(() => import('../subscriber/sendmail.js')),
           name: '/admin'
          },
          {
           path: "/subscriberlist",
           component: lazy(() => import('../subscriber/subscriberlist.js')),
           name: '/admin'
          },
          {
            path: "/transactionlist",
            component: lazy(() => import('../transaction/transcationlist.js')),
            name: '/admin'
           },   {
            path: "/levelslist",
            component: lazy(() => import('../levels/levelslist.js')),
            name: '/admin'
           },{
            path: "/levelsdetail",
            component: lazy(() => import('../levels/levelsdetail.js')),
            name: '/admin'
           },
           {
            path: "/transcationdetails",
            component: lazy(() => import('../transaction/transcationdetails.js')),
            name: '/admin'
           },
           

           
        ]


export default Routes;
