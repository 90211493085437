import { Encryptdata } from "../lib/encode_decode"

export const AppenData = (data) => {
    var formdata = new FormData()
    var SendDta = Object.entries(data).map((item) => {
        if (Array.isArray(item[1])) {
            var come = item[1].map((data) => {
                if (data?.type && data?.size) {
                    //file
                    formdata.append(item[0], data)
                }
                else {
                    formdata.append(item[0], Encryptdata(data))
  
                }
                return formdata
            })
            return come
  
        }
        else {
            if (item[1]?.type && item[1]?.size) {
                //file type
                formdata.append(item[0], item[1])
            }
            else {
                formdata.append(item[0], Encryptdata(item[1]))
  
            }
            return formdata
        }
    })
    return SendDta
  }